import React, { Component } from "react"
import PageHeader from "../components/pageHeader"
import Layout from "../components/layout"
import styled from "styled-components"
import SEO from "../components/seo"
import { graphql } from "gatsby"

const Cover = styled.img`
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  height: 100vh;
  width: 100%;
  object-fit: cover;
`
const Container = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  right: auto;
  bottom: auto;
  margin-right: -50%;
  transform: translate(-50%, -50%);
`
const ContactButton = styled.a`
  font-family: "alternategothic2_btregular";
  color: black;
  background: white;
  padding: 10px 20px;
  font-size: 1.8rem;
  text-decoration: none;

  transition: 0.3s ease;

  :hover {
    color: white;
    background: black;
  }
`
class ContactPage extends Component {
  state = {}
  render() {
    const pageData = this.props.data.allWordpressPage.edges[0].node

    return (
      <Layout>
        <SEO title="Contact" />
        <PageHeader>Contact</PageHeader>
        <Cover src={pageData.acf.cover.source_url} />
        <Container>
          <ContactButton href="mailto:mikegalofre@gmail.com">
            mikegalofre@gmail.com
          </ContactButton>
        </Container>
      </Layout>
    )
  }
}

export default ContactPage

export const contactQuery = graphql`
  query($homepage: String = "Contact") {
    allWordpressPage(filter: { title: { eq: $homepage } }) {
      edges {
        node {
          title
          acf {
            cover {
              source_url
              alt_text
            }
          }
        }
      }
    }
  }
`
